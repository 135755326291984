<template>
  <div class="error-box blue-grey lighten-5">
    <div class="py-12">
      <div class="text-center">
        <h1 class="error-title error--text">
          {{ $store.getters.translate("no_access") }}
        </h1>
        <h3 class="text-uppercase error-subtitle">
          {{ $store.getters.translate("you_dont_have_access") }}
        </h3>
        <v-btn class="mt-9" color="info" @click="$router.back(2)">
          {{ $store.getters.translate("go_back") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  data: () => ({}),
};
</script>

<style lang="scss">
.error-box {
  height: 100%;
  width: 100%;
  position: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background: url("../../assets/images/background/error-bg.jpg");
}
.error-title {
  font-size: 100px;
  font-weight: 900;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
  line-height: 140px;
}

@media (max-width: 991px) {
  .error-title {
    font-size: 50px;
    line-height: 80px;
  }
}

@media (max-width: 767px) {
  .error-title {
    font-size: 40px;
    line-height: 40px;
  }
}
</style>